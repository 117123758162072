import React from 'react'
import { Box } from 'grommet'
import { SignIn } from '@clerk/clerk-react'

export const Login = () => {
  return (
    <Box direction="row" flex align="center" justify="center">
      <Box flex align="center" justify="center">
        <SignIn signUpUrl="/signup" fallbackRedirectUrl="/" />
      </Box>
    </Box>
  )
}
