import React, { useState, useEffect } from 'react'
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Text,
  Heading,
  Button,
} from 'grommet'
import { Trash, Basket } from 'grommet-icons'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { deletePresent, selectPresent, unSelectPresent } from './thunk'
import {
  map,
  flowRight,
  join,
  get,
  filter,
  first,
  isEmpty,
  includes,
} from 'lodash/fp'

export const Present = ({ name, description, price, ownerId, id }) => {
  useFirestoreConnect([
    {
      collection: 'buyers',
      where: [['presentId', '==', id]],
      storeAs: `buyer${id}`,
    },
  ])

  const auth = useSelector((state) => state.firebase.auth)
  const { forbidden } = useSelector((state) => state.firebase.profile)
  const buyers = useSelector((state) => state.firestore.ordered[`buyer${id}`])

  const [iAmBuyer, setIAmBuyer] = useState({})
  const [areYouABuyer, setAreYouABuyer] = useState(false)

  const canSeeBuyers = !includes(ownerId)(forbidden) || areYouABuyer

  useEffect(() => {
    const buyer = first(filter(['buyerId', auth.uid])(buyers))
    setIAmBuyer(buyer)
    setAreYouABuyer(Boolean(buyer?.id))
  }, [buyers, auth.uid])

  const dispatch = useDispatch()

  const handleDelete = () => {
    dispatch(deletePresent(id))
  }

  const handleSelect = () => {
    dispatch(
      selectPresent({
        presentId: id,
        ownerId,
      })
    )
  }

  const handleUnSelect = () => {
    dispatch(unSelectPresent(iAmBuyer?.id))
  }

  return (
    <Box pad="medium">
      <Card height="medium" width="medium" background="light-1">
        <CardHeader pad="medium" background="light-3">
          {name}
        </CardHeader>
        <CardBody pad="medium" overflow="auto">
          {!isEmpty(buyers) && canSeeBuyers && (
            <>
              <Heading level="4">Selectionné par:</Heading>
              <Text color="green">
                {flowRight(join(', '), map(get('buyerName')))(buyers)}
              </Text>
            </>
          )}
          <Heading level="4">Description:</Heading>
          <Text>{description}</Text>
        </CardBody>
        <CardFooter pad={{ horizontal: 'small' }} background="light-2">
          <Text hoverIndicator>Prix: {price}</Text>
          {auth.uid === ownerId ? (
            <Button
              icon={<Trash color="plain" />}
              hoverIndicator
              onClick={handleDelete}
            />
          ) : (
            <Button
              icon={<Basket color={areYouABuyer ? '#ea4630' : 'plain'} />}
              hoverIndicator
              onClick={areYouABuyer ? handleUnSelect : handleSelect}
            />
          )}
        </CardFooter>
      </Card>
    </Box>
  )
}
