import React from 'react'
import { Box } from 'grommet'
import { SignUp } from '@clerk/clerk-react'

export const Register = () => {
  return (
    <Box direction="row" flex align="center" justify="center">
      <Box flex align="center" justify="center">
        <SignUp signInUrl="/login" fallbackRedirectUrl="/" />
      </Box>
    </Box>
  )
}
