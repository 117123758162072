import React from 'react'
import { Box, Button, Heading, Nav } from 'grommet'
import { AddCircle } from 'grommet-icons'
import { SignedIn, UserButton } from '@clerk/clerk-react'

export const AppBar = ({ showSidebar, setShowSidebar }) => {
  return (
    <Box
      tag="header"
      direction="row"
      align="center"
      justify="between"
      background="brand"
      pad={{ left: 'medium', right: 'small', vertical: 'small' }}
      elevation="medium"
      style={{ zIndex: '1' }}
    >
      <Heading level="3" margin="none">
        🎄🎄🎄 NOËL 🎄🎄🎄
      </Heading>

      <Box direction="row">
        <Nav direction="row" background="brand" align="end">
          <SignedIn>
            <Button
              icon={<AddCircle color="#f8b229" />}
              onClick={() => setShowSidebar(!showSidebar)}
            />
          </SignedIn>
        </Nav>
        <SignedIn>
          <UserButton />
        </SignedIn>
      </Box>
    </Box>
  )
}
