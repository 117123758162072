import React, { useState } from 'react'
import { Box, Grommet, ResponsiveContext } from 'grommet'
import { AppBar } from './components/AppBar'
import { Sidebar } from './components/Sidebar'
import { Router } from '@reach/router'
import { Dashboard } from './dashboard'
import { Login, Register } from './auth'

const theme = {
  global: {
    colors: {
      brand: '#165b33',
    },
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
  },
}

const App = () => {
  const [showSidebar, setShowSidebar] = useState(false)

  return (
    <Grommet theme={theme} full>
      <ResponsiveContext.Consumer>
        {() => (
          <>
            <Box direction="column">
              <AppBar
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
              />
              <Box direction="row" flex overflow={{ horizontal: 'hidden' }}>
                <Router
                  style={{
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <Dashboard path="/" />

                  <Login path="/login" />
                  <Register path="/signup" />
                </Router>
                <Sidebar
                  showSidebar={showSidebar}
                  setShowSidebar={setShowSidebar}
                />
              </Box>
              <img
                src="/bg-bottom.png"
                alt="landscap"
                style={{ width: '100vw' }}
              />
            </Box>
          </>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  )
}

export default App
