import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { SignedIn, SignedOut, useAuth } from '@clerk/clerk-react'
import { firebase as firebaeConfig } from '../config/firebase-config'
import { Redirect } from '@reach/router'
import { useFirebase } from 'react-redux-firebase'

export const Firebaseconnection = ({ children }) => {
  const auth = useSelector((state) => state.firebase.auth)

  const firebase = useFirebase()
  const { getToken, userId } = useAuth()

  useEffect(() => {
    const signIntoFirebaseWithClerk = async () => {
      console.log('signIntoFirebaseWithClerk', userId, auth)
      if (userId && auth.isEmpty) {
        console.log('if (userId && auth.isEmpty)')
        const token = await getToken({ template: 'integration_firebase' })

        firebaeConfig.auth().signInWithCustomToken(token || '')
      }
      if (!userId && !auth.isEmpty) {
        console.log('if (!userId && !auth.isEmpty)')
        firebase.auth().signOut()
      }
    }

    signIntoFirebaseWithClerk()
  }, [userId, getToken, auth, firebase])

  return (
    <>
      <SignedIn>{auth.isEmpty ? null : children} </SignedIn>
      <SignedOut>
        <Redirect to="/login" noThrow />
      </SignedOut>
    </>
  )
}
