import React, { useState } from 'react'
import { Main, Box, Heading, TextInput } from 'grommet'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { map, groupBy, flowRight, filter, includes, lowerCase } from 'lodash/fp'
import { Sign } from 'grommet-icons'
import { Present } from '../presents'
import { Firebaseconnection } from '../auth'

const DashboardComponent = () => {
  useFirestoreConnect([
    { collection: 'presents', orderBy: ['ownerId', 'desc'] },
  ])
  const firebase = useSelector((state) => state.firebase)
  const presents = useSelector((state) => state.firestore.ordered.presents)

  const { profile } = firebase

  const [search, setSearch] = useState('')

  const sortedPresent = flowRight(
    groupBy((present) => `${present.ownerFirstName} ${present.ownerLastName}`),
    filter((present) =>
      includes(lowerCase(search))(lowerCase(present.ownerFirstName))
    )
  )(presents)

  return (
    <Box direction="column">
      <Main pad="medium" direction="column" style={{ overflow: 'visible' }}>
        <Heading level="2" color="#bb2528">
          Yo <Sign /> {profile.firstName}
        </Heading>
        <div>
          <TextInput
            placeholder="cherche une personne par prenom..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div>
          {map.convert({ cap: false })((presents, id) => (
            <Box key={id}>
              <Heading level={3}> Liste de {id} 🎁🎁🎁</Heading>
              <Box direction="row" justify="start" wrap>
                {map((present) => <Present {...present} key={present.id} />)(
                  presents
                )}
              </Box>
            </Box>
          ))(sortedPresent)}
        </div>
      </Main>
    </Box>
  )
}

export const Dashboard = () => {
  return (
    <Firebaseconnection>
      <DashboardComponent />
    </Firebaseconnection>
  )
}
